<template>
  <Toast />
  <ConfirmDialog />
  <div v-if="cargandoComponente">
    <div class="grid" v-if="permitirVenta">
      <div class="col-12 md:col-8">
        <div class="card">
          <div class="p-fluid formgrid grid p-fondo">
            <div class="field col-12 md:col-5 mt-2">
              <span><strong>BUSCAR PACIENTE:</strong></span>
              <div class="p-inputgroup">
                <AutoComplete
                  field="nombre"
                  v-model="clienteSelect"
                  :suggestions="clientes"
                  placeholder="Buscar Paciente"
                  v-tooltip.top="'Buscar Todos los Pacientes'"
                  @keyup.enter="buscarCliente($event)"
                  autofocus
                >
                  <template #item="slotProps">
                    <div>
                      <strong>{{ slotProps.item.nombre }}</strong
                      ><span><strong> - CODIGO:</strong></span>
                      {{ slotProps.item.id }}
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  icon="pi pi-search"
                  class="p-button-warning"
                  v-tooltip.top="'Buscar Paciente'"
                  @click="buscarCliente($event)"
                />
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-user"
                  class="p-button-info"
                  v-tooltip.top="'Nuevo Cliente'"
                  @click="activarCliente"
                />
              </div>
            </div>
            <div class="field col-12 md:col-3 mt-2">
              <span><strong>CI/NIT:</strong></span>
              <div class="p-inputgroup">
                <InputText
                  v-tooltip.top="'Nit a Facturar'"
                  v-model="nit"
                  placeholder="0"
                  :readonly="!facturar"
                  @keyup.enter="buscarClientePorNit"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-search"
                  class="p-button-warning"
                  v-tooltip.top="'Buscar NIT'"
                  @click="buscarClientePorNit"
                  :disabled="!facturar"
                />
                <Button
                  v-if="facturar && !this.razon_social"
                  icon="pi pi-id-card"
                  class="p-button-info"
                  v-tooltip.top="'Registrar NIT'"
                  @click="activarClienteFactura"
                />
              </div>
            </div>
            <div class="field col-12 md:col-4 mt-2">
              <span><strong>RAZ&Oacute;N SOCIAL:</strong></span>
              <div class="p-inputgroup">
                <Textarea
                  v-model="razon_social"
                  placeholder="Razón Social"
                  readonly
                  rows="1"
                  autoResize
                />
                <!-- :disabled="!facturar" -->
              </div>
            </div>
          </div>
          <div
            class="p-fluid formgrid grid mt-2"
            v-if="'Venta ConMedico' in auth.user.permissions"
          >
            <div class="field col-12 md:col-5 mb-0">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span><strong>M&Eacute;DICO:</strong></span>
                  <Checkbox
                    v-model="medico_referencia"
                    :binary="true"
                    v-tooltip.top="'Médico Referencia'"
                    disabled="true"
                  />
                </span>
                <Dropdown
                  v-if="medico_referencia == true"
                  v-model="medicoSelected"
                  :options="medicos"
                  placeholder="Seleccione..."
                  optionLabel="nombre_completo"
                  optionValue="id"
                  :filter="true"
                />
                <Button
                  v-show="medico_referencia && medicoSelected"
                  icon="pi pi-eye"
                  class="p-button-info"
                  v-tooltip.top="'Ver Médicos Asignados'"
                  @click="ver_medico_detalle = !ver_medico_detalle"
                />
              </div>
            </div>
            <div
              :class="
                solicitante == true
                  ? 'field col-12 md:col-5'
                  : 'field col-12 md:col-3'
              "
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span><strong>SOLICITANTE:</strong></span>

                  <Checkbox
                    v-model="solicitante"
                    :binary="true"
                    v-tooltip.top="'Solicitante'"
                  />
                </span>
                <Dropdown
                  v-if="solicitante == true"
                  v-model="solicitanteSelected"
                  :options="medicos"
                  placeholder="Seleccione..."
                  optionLabel="nombre_completo"
                  optionValue="nombre_completo"
                  :filter="true"
                />
              </div>
            </div>
            <div
              :class="
                solicitante == true
                  ? 'field col-12 md:col-2'
                  : 'field col-12 md:col-2'
              "
            >
              <Button
                icon="pi pi-search"
                label="HISTÓRICO"
                class="p-button-info"
                v-tooltip.top="'Ver Atenciones Anteriores'"
                @click="nuevoAperturaModalHistorialCliente"
                :disabled="!clienteSelect"
              />
            </div>
            <div class="field col-12 md:col-2">
              <Button
                :disabled="!clienteSelect"
                v-if="es_de_odontologia || es_de_emergencia"
                label="DEUDA"
                icon="pi pi-money-bill"
                class="p-button-danger"
                v-tooltip.top="'Ver Deuda'"
                @click="verDeudaCliente"
                :loading="cargandoDeuda"
              />
            </div>
            <div
              class="field col-12 md:col-6"
              v-show="false"
              v-if="'Venta VenderPaquete' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <h5><strong>PAQUETES: </strong></h5>

                <Checkbox
                  v-model="vender_paquete"
                  value="vender_paquete"
                  class="ml-4 mr-2"
                  v-tooltip.top="'Vender Paquete'"
                />
                <Dropdown
                  v-if="vender_paquete == 'vender_paquete'"
                  v-model="paqueteSelected"
                  :options="paqueteproductos"
                  placeholder="Seleccione..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />

                <Button
                  v-if="vender_paquete == 'vender_paquete'"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-info p-button-outlined ml-2"
                  v-tooltip.top="'Agregar Paquete al Detalle'"
                  @click="agregarProductosPaqueteAlDetalle"
                />
              </div>
            </div>
          </div>
          <Panel
            toggleable
            :collapsed="true"
            class="shadow-2"
            @update:collapsed="actualizarPanelMasFiltros"
          >
            <template #header>
              <div class="p-inputgroup">
                <AutoComplete
                  field="descripcion"
                  placeholder="Buscar... Nombre de Producto"
                  v-tooltip.top="'Buscar Producto por Nombre'"
                  v-model="productoSelecionado"
                  :loading="true"
                  :delay="300"
                  :suggestions="productosListadoFiltrado"
                  @complete="buscarProducto($event)"
                >
                  <template #item="slotProps">
                    <div class="flex align-options-center">
                      <div
                        class="text-overflow-ellipsis overflow-hidden p-0"
                        :class="
                          computedBuscarProductoPorSelected ? 'col-5' : 'col-7'
                        "
                        style="font-weight: bold; font-size: 12px"
                        :title="slotProps.item.descripcion"
                      >
                        {{ slotProps.item.descripcion }}
                      </div>
                      <div
                        class="col-3 text-overflow-ellipsis overflow-hidden p-0"
                        style="font-size: 12px"
                        :title="slotProps.item.fabrica_nombre"
                      >
                        {{
                          slotProps.item.fabrica_nombre.length > 15
                            ? slotProps.item.fabrica_nombre.substring(0, 15) +
                              "..."
                            : slotProps.item.fabrica_nombre
                        }}
                      </div>
                      <div
                        class="col-3 p-0 mr-1 text-overflow-ellipsis overflow-hidden"
                        style="background-color: aqua; border-radius: 2px"
                        v-if="
                          computedBuscarProductoPorSelected &&
                          slotProps.item.caso_uso != null &&
                          slotProps.item.caso_uso != ''
                        "
                        v-tooltip.top="slotProps.item.caso_uso"
                      >
                        {{ slotProps.item.caso_uso.substring(0, 15) }}...
                      </div>
                      <div
                        class="col-2 flex align-items-center justify-content-center p-0"
                        style="
                          font-size: 14px;
                          color: #ffffff;
                          background-color: #f44336;
                          border-radius: 2px;
                        "
                      >
                        <strong>
                          Bs
                          {{
                            slotProps.item.stock.length > 0
                              ? slotProps.item.stock[0].ultimo_precio
                              : "-"
                          }}
                        </strong>
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  icon="pi pi-search"
                  class="p-button-info"
                  v-tooltip.top="'VER RESULTADOS DE BUSQUEDA'"
                  :disabled="productosListadoFiltrado.length == 0"
                  @click="activarModalProductosFiltrados"
                ></Button>
              </div>
            </template>
            <div class="grid">
              <div class="col-12 md:col-4" v-show="false">
                <span><strong>C&Oacute;D BARRA:</strong></span>
                <AutoComplete
                  field="codigo_barra"
                  v-model="codProdSelected"
                  :suggestions="codProdListadoFiltrado"
                  v-tooltip.top="'Código'"
                  placeholder="Código..."
                  @item-select="buscarCodProducto"
                  @complete="buscarCodProducto"
                  @keyup.enter="buscarCodProducto"
                  autocomplete="off"
                />
              </div>
              <!-- Alinear de forma horizontal -->

              <div class="col-12 flex flex-align-left">
                <div
                  v-for="filtro of buscarProductoPorListado"
                  :key="filtro.value"
                  class="col-3 pr-1 mr-1"
                >
                  <Checkbox
                    v-model="buscarProductoPorSelected"
                    :inputId="filtro.value"
                    name="filtro"
                    :value="filtro.value"
                  />
                  <span class="ml-1" :for="filtro.value">{{
                    filtro.label
                  }}</span>
                </div>
              </div>
            </div>
          </Panel>
          <DataTable
            ref="dt_venta_detalle"
            class="p-datatable-sm mt-4"
            dataKey="id"
            :value="productosVentas"
            responsiveLayout="scroll"
            scrollHeight="400px"
            editMode="cell"
            @cell-edit-complete="guardandoCambiosCelda"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
          >
            <template #empty>
              <span
                class="flex align-items-center justify-content-center"
                style="
                  background-color: #f8d7da;
                  padding: 5px;
                  border-radius: 5px;
                  color: #721c24;
                "
                >Busca Productos para agregar al Detalle!</span
              ></template
            >
            <Column header="#">
              <template #body="slotProps">
                {{ 1 + slotProps.index }}
              </template>
            </Column>
            <!-- <Column
                field="nombre_producto"
                header="DESCRIPCIÓN/PRODUCTO"
                style="font-weight: bold"
              >
                {{ data.nombre_producto }}
              </Column> -->
            <Column field="nombre_producto" header="PRODUCTO/SERVICIO">
              <template #body="{ data }">
                <span>
                  <strong>{{ data.nombre_producto }}</strong
                  ><strong> ** </strong>
                  <span v-if="data.nombre_fabrica != null">
                    {{ data.nombre_fabrica }}
                  </span>
                  <span v-else-if="data.fabrica_nombre != null">
                    {{ data.fabrica_nombre }}
                  </span>
                  <span v-else>{{
                    data.producto.fabrica_nombre != null
                      ? data.producto.fabrica_nombre
                      : ""
                  }}</span>
                </span>
              </template>
            </Column>
            <!-- <Column class="text-right strong" field="cantidad" header="STOCK">
                <template #body="{ data }">
                  <span :class="claseEstado(data)">
                    <strong> {{ sinDecimal(data.cantidad) }}</strong>
                  </span>
                </template>
              </Column> -->
            <Column
              class="text-right strong bg-green-200"
              field="cantidad_venta"
              header="CANTIDAD"
              style="font-weight: bold; font-size: 1rem; width: 10px"
            >
              <template #body="slotProps">
                {{ slotProps.data.cantidad_venta }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="flex-row-reverse"
                  locale="de-DE"
                  class="p-inputtext-sm"
                  :min="1"
                />
              </template>
            </Column>
            <Column
              class="text-right strong"
              field="ultimo_precio"
              header="PRECIO"
              style="font-size: 1rem; width: 20px"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.ultimo_precio ?? 0
                  )
                }}
              </template>
              <template
                #editor="{ data, field }"
                v-if="'Venta EditarPrecio' in this.auth.user.permissions"
              >
                <InputNumber
                  v-model="data[field]"
                  :inputClass="'text-right'"
                  :min="0"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column
              v-if="aplica_descuento == true"
              class="flex-row-reverse strong bg-green-200"
              field="descuento_neto"
              header="DESC."
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.descuento_neto ?? 0
                  )
                }}
              </template>
              <template #editor="slotProps">
                <InputNumber
                  :inputClass="'text-right'"
                  :min="0"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                  v-model="slotProps.data.descuento_neto"
                />
              </template>
            </Column>
            <Column
              class="text-right"
              field="total"
              header="TOTAL"
              style="font-size: 1rem; width: 20px"
            >
              <template #body="slotProps">
                <!-- hago v-if si slotProps.data.ultimo_precio *
                        slotProps.data.cantidad_venta = 0 entonces no aplico descuento 
                      {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.ultimo_precio *
                        slotProps.data.cantidad_venta -
                        slotProps.data.descuento_neto
                    )
                  }}-->
                <span
                  v-if="
                    slotProps.data.ultimo_precio *
                      slotProps.data.cantidad_venta ==
                    0
                  "
                >
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.ultimo_precio *
                        slotProps.data.cantidad_venta
                    )
                  }}
                </span>
                <span v-else>
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.ultimo_precio *
                        slotProps.data.cantidad_venta -
                        slotProps.data.descuento_neto
                    )
                  }}
                </span>
              </template>
            </Column>
            <Column
              v-if="ver_medico_detalle"
              field="nombre_medico"
              header="MÉDICO"
              style="font-size: 1rem; width: 20px"
            >
              <template #body="slotProps">
                {{ slotProps.data.nombre_medico }}
              </template>
              <template #editor="slotProps">
                <Dropdown
                  v-model="slotProps.data.medico_id"
                  :options="medicos"
                  optionLabel="nombre_completo"
                  optionValue="id"
                  placeholder="Seleccione un médico"
                />
              </template>
            </Column>
            <!-- <Column
                v-if="aplica_descuento == 'aplica_descuento'"
                class="flex-row-reverse strong"
                field="descuento_neto"
                header="Desct. Neto"
              >
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(slotProps.data.descuento_neto)
                  }}
                </template>
                <template #editor="slotProps">
                  <InputNumber
                    :inputClass="'text-right'"
                    :min="0"
                    :minFractionDigits="2"
                    locale="de-DE"
                    v-model="slotProps.data.descuento_neto"
                  />
                </template>
              </Column>
              <Column
                v-if="aplica_descuento == 'aplica_descuento'"
                class="text-right strong"
                field="descuento_porcentaje"
                header="Desct. %"
              >
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.descuento_porcentaje
                    ) + "%"
                  }}
                </template>
                <template #editor="slotProps">
                  <InputNumber
                    :inputClass="'text-right'"
                    :min="0"
                    :minFractionDigits="2"
                    locale="de-DE"
                    v-model="slotProps.data.descuento_porcentaje"
                  />
                </template>
              </Column> -->
            <!-- <Column>
                <template #header>
                  <i class="pi pi-check"></i>
                </template>
                <template #body="slotProps">
                  <Checkbox
                    v-model="slotProps.data.entregado"
                    value="entregado"
                    :disabled="slotProps.data.cantidad_venta == 0"
                    v-tooltip.top="'Entregado'"
                  />
                </template>
              </Column> -->
            <Column class="strong" style="width: 10px; text-align: center">
              <template #header>
                <i class="pi pi-trash"></i>
              </template>
              <template #body="slotProps">
                <Button
                  @click="quitarProductoDetalle(slotProps)"
                  v-tooltip.top="'Quitar Producto'"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-outlined"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="card">
          <h4><strong>TOTALES DE VENTAS:</strong></h4>
          <div class="p-fluid formgrid grid">
            <table class="field col-12 md:col-12" style="width: 100%">
              <tr>
                <td>
                  <strong>SUB TOTAL:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(subtotal) }} Bs.
                </td>
              </tr>
              <tr v-if="aplica_descuento == true">
                <td>
                  <strong>DESCUENTO:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(descuento) }} Bs.
                </td>
              </tr>
              <tr>
                <td>
                  <h4><strong>TOTAL VENTA:</strong></h4>
                </td>
                <td class="text-right">
                  <h2>{{ convertirNumeroGermanicFormat(total_venta) }} Bs.</h2>
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>A CUENTA:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(a_cuenta + monto_pago) }} Bs.
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>SALDO:</strong>
                </td>
                <td class="text-right">
                  {{
                    convertirNumeroGermanicFormat(
                      total_venta - a_cuenta - monto_pago
                    )
                  }}
                  Bs.
                </td>
              </tr>
            </table>
            <div class="field col-12 md:col-12" v-if="facturar" v-show="false">
              <span><strong>ACTIVIDAD ECONOMICA:</strong></span>
              <div class="p-inputgroup">
                <Dropdown
                  v-model="actividad_economica_selected"
                  :options="actividades_economicas"
                  option-label="descripcion"
                  option-value="codigo"
                  placeholder="Seleccione una actividad economica"
                  @change="cambiarActividadEconomica"
                />
              </div>
            </div>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <template v-if="permitir_facturar">
                  <Checkbox
                    class="mr-1"
                    value="facturar"
                    v-model="facturar"
                    :binary="true"
                    v-tooltip.top="'Facturar'"
                    :disabled="
                      'Venta Facturar' in auth.user.permissions == false ||
                      total_venta == 0
                    "
                  /><span class="p-tag p-tag-danger">ENVIAR</span>
                </template>
                <Checkbox
                  class="ml-1 mr-1"
                  :binary="true"
                  :disabled="
                    'Venta Descuentos' in auth.user.permissions == false ||
                    descuento > 0 ||
                    total_venta == 0
                  "
                  v-model="aplica_descuento"
                  v-tooltip.top="'Aplicar Descuento'"
                />
                <span class="p-tag p-tag-warning">DESCUENTO</span>
                <Checkbox
                  class="ml-1 mr-1"
                  :binary="true"
                  v-tooltip.top="'Observación'"
                  v-model="observacion_check"
                />
                <span class="p-tag p-tag-info">OBSERVACIÓN</span>
              </div>
            </div>
            <div
              class="field col-12 md:col-12"
              v-if="observacion_check == true"
            >
              <Textarea
                v-model="observacion"
                rows="3"
                autoResize
                placeholder="Glosa/observación"
              />
            </div>
            <template v-if="aplica_descuento == true">
              <table class="field col-12 md:col-12">
                <tr>
                  <td>
                    <strong>DESC. NETO :</strong>
                  </td>
                  <td class="text-right">
                    <InputNumber
                      v-model="descuento"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="subtotal"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento neto"
                      @keyup="calcular_agregar_des_total_venta"
                    />
                  </td>
                  <td class="text-right">
                    <Button
                      icon="pi pi-check"
                      class="p-button-outlined p-button-secondary"
                      v-tooltip.top="'Descuento neto'"
                      @click="calcular_agregar_des_total_venta"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>DESCUENTO %:</strong>
                  </td>
                  <td class="text-right strong">
                    <InputNumber
                      v-model="desc_venta_porcentaje"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="100"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento %"
                      @keyup="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                  <td class="text-right">
                    <Button
                      v-tooltip.top="'Descuento %'"
                      icon="pi pi-check"
                      class="p-button-outlined p-button-secondary"
                      @click="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                </tr>
              </table>
            </template>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <Checkbox
                  class="mr-1"
                  v-model="a_credito_check"
                  :binary="true"
                  v-tooltip.top="'Seleccionar tipo de pago'"
                  :disabled="
                    'Venta Credito' in auth.user.permissions == false ||
                    total_venta == 0
                  "
                /><span class="p-tag p-tag-primary">A CUENTA</span>
                <Checkbox
                  class="ml-1 mr-1"
                  v-model="metodo_pago_check"
                  :binary="true"
                  :disabled="
                    'Venta M.Pagos' in auth.user.permissions == false ||
                    total_venta == 0
                  "
                  v-tooltip.top="'Seleccionar Método de Pago'"
                /><span class="p-tag p-tag-success">PAGOS QR</span>
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <table style="width: 100%">
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>EFECTIVO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="a_cuenta"
                      :min="0"
                      :max="total_venta - monto_pago - 1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :minFractionDigits="2"
                      :useGrouping="false"
                      placeholder="a cuenta efectivo"
                    />
                    <!--  :disabled="metodo_pagoSelect != 1" -->
                  </td>
                </tr>
                <tr v-if="metodo_pago_check">
                  <td>
                    <strong>M. PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      v-model="metodo_pagoSelect"
                      :options="metodo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr
                  v-if="
                    metodo_pago_check &&
                    metodo_pagoSelect == 6 &&
                    permitir_pago_qr_banco
                  "
                >
                  <td>
                    <strong>GENERAR QR: </strong>
                  </td>
                  <td>
                    <Button
                      label="GENERAR QR"
                      icon="pi pi-qrcode"
                      class="p-button-info"
                      @click="generarQR"
                      :loading="generando_qr"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect == 6 && generar_qr">
                  <td class="text-center">
                    <span
                      v-if="estado_qr_generado == 0"
                      style="
                        color: #d9534f;
                        font-size: 1rem;
                        text-align: center;
                      "
                    >
                      <i
                        class="pi pi-spin pi-spinner"
                        style="font-size: 2rem"
                      ></i
                      ><br />
                      <strong>PENDIENTE DE PAGO...</strong>
                    </span>
                    <span
                      v-else-if="estado_qr_generado == 1"
                      style="
                        color: #28a745;
                        font-size: 1rem;
                        text-align: center;
                      "
                    >
                      <i class="pi pi-check" style="font-size: 2rem"></i><br />
                      <strong>PAGADO</strong>
                    </span>
                    <span
                      v-else-if="estado_qr_generado == 9"
                      style="
                        color: #721c24;
                        font-size: 1rem;
                        text-align: center;
                      "
                    >
                      <i class="pi pi-times" style="font-size: 2rem"></i><br />
                      <strong>ANULADO</strong>
                    </span>
                  </td>

                  <td>
                    <Image
                      :src="'data:image/png;base64, ' + qrImage"
                      width="150"
                      preview
                    ></Image>
                    <Button
                      label="CANCELAR QR"
                      icon="pi pi-times"
                      class="p-button-danger"
                      @click="cancelarQR"
                      :loading="cancelando_qr"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect == 2 && facturar == 1">
                  <td>
                    <strong>Nº TARJETA: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="nro_tarjeta"
                      :min="8"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      placeholder="Ingrese el número de tarjeta"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect != 1">
                  <td>
                    <strong>MONTO PAGO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="monto_pago"
                      :min="0"
                      :max="total_venta - a_cuenta"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      :minFractionDigits="2"
                      placeholder="monto pago transferencias"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="field col-12 md:col-6 hidden">
              <span><strong>Cotizaci&oacute;n</strong></span>
              <Checkbox class="ml-3" value="cotizar" v-model="cotizar" />
            </div>
            <div class="field col-12 md:col-12">
              <table style="width: 100%">
                <tr v-if="es_de_odontologia && clienteSelect">
                  <td>
                    <strong>SALDO A FAVOR:</strong>
                  </td>
                  <td class="text-right">
                    <span
                      :style="{
                        color: cliente_saldo_final > 0 ? 'green' : 'red',
                        'font-weight': 'bold',
                        'font-size': '1.3rem',
                      }"
                    >
                      {{ convertirNumeroGermanicFormat(cliente_saldo_final) }}
                      Bs.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>RECIBIDO:</strong>
                  </td>
                  <td class="text-right">
                    <InputNumber
                      v-model="efectivo"
                      :min="minimo_recibir"
                      locale="de-DE"
                      input-class="text-right"
                      :minFractionDigits="2"
                      :disabled="
                        metodo_pagoSelect != 1
                          ? true
                          : false || a_credito_check || total_venta == 0
                      "
                    />
                  </td>
                </tr>
                <tr
                  v-if="abonar_cuenta && this.cambio > 0"
                  style="background-color: lightskyblue"
                >
                  <td>
                    <strong>ABONAR A CUENTA:</strong>
                  </td>
                  <td class="text-right">
                    <Checkbox v-model="es_abonar_cuenta" :binary="true" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <h2>CAMBIO:</h2>
                    </strong>
                  </td>
                  <td class="text-right p-invalid">
                    <strong>
                      <h1>{{ convertirNumeroGermanicFormat(cambio) }} Bs.</h1>
                    </strong>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-12 md:col-12 flex justify-content-end">
              <Button
                :label="facturar == 1 ? 'REGISTRAR FACTURA' : 'REGISTRAR VENTA'"
                icon="pi pi-save"
                v-tooltip.top="'Registrar Venta'"
                class="p-button-info p-button-lg"
                @click="guardarVenta"
                :disabled="productosVentas.length == 0"
                :loading="guardando"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-else>
      <Message class="col-12" :severity="'info'" :closable="false">
        <div class="flex align-items-center justify-content-center">
          <span> PARA REALIZAR VENTAS DEBE ABRIR LA CAJA </span>
          <Button
            label="APERTURAR CAJA"
            class="p-button-success p-button-lg ml-3"
            icon="pi pi-lock-open"
            v-tooltip.top="'Abrir Caja'"
            @click="this.$router.push({ name: 'caja' })"
          />
        </div>
      </Message>
    </div>
  </div>
  <div v-else>
    <div class="grid">
      <h3 class="col-12">Verificando caja ...</h3>
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        class="col-12"
      />
    </div>
  </div>
  <Dialog
    v-model:visible="openHistorialCliente"
    modal
    header="HISTORIAL DE ATENCIONES"
    :style="{ width: '450px' }"
  >
    <div class="p-fluid">
      <div class="p-field">
        <DataTable
          ref="dt_historial_cliente"
          :value="historiales_cliente"
          dataKey="id"
          class="p-datatable-sm"
          stripedRows
          showGridlines
        >
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column
            field="fecha_venta"
            header="FECHA"
            style="font-size: 1rem; font-weight: bold"
          ></Column>
          <Column
            field="nombre_medico"
            header="MÉDICO"
            style="font-size: 1rem; font-weight: bold"
          ></Column>
          <Column
            field="descripcion"
            header="PRODUCTO/SERVICIO"
            style="font-size: 1rem; font-weight: bold"
          ></Column>
        </DataTable>
      </div>
    </div>
    <div class="flex justify-content-end mt-4">
      <Button
        label="CERRAR"
        icon="pi pi-times"
        class="p-button-primary mr-2 p-button-lg"
        @click="(openHistorialCliente = false), (this.historiales_cliente = [])"
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="modalDeudasCliente"
    modal
    :header="`DEUDA DE:  ${clienteSelect ? clienteSelect.nombre : ''}`"
    :style="{ width: '750px' }"
  >
    <div class="p-fluid">
      <div class="col-12">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <span><strong>DEUDAS DE:</strong></span>
          </span>
          <Button
            :class="[
              'estiloBoton',
              { activo: selectedDeuda === 'odontologia' },
            ]"
            class="mr-1"
            label="Odontología"
            @click="selectedDeuda = 'odontologia'"
          />
          <Button
            :class="['estiloBoton', { activo: selectedDeuda === 'emergencia' }]"
            label="Emergencia"
            @click="selectedDeuda = 'emergencia'"
          />
        </div>
      </div>

      <DataTable
        ref="dt_deudas_cliente"
        :value="
          selectedDeuda === 'odontologia' ? deudas_clientes : deudas_emergencias
        "
        dataKey="id"
        class="p-datatable-sm"
        stripedRows
        showGridlines
      >
        <Column field="nombre_medico" header="MÉDICO"></Column>
        <Column
          field="tratamiento"
          header="TRATAMIENTO"
          style="font-size: 1rem; font-weight: bold"
        ></Column>
        <Column
          field="dientes"
          header="PIEZA"
          style="font-size: 1rem; font-weight: bold; text-align: center"
          v-if="selectedDeuda === 'odontologia'"
        ></Column>
        <Column
          field="cantidad"
          header="CANTIDAD"
          style="font-size: 1rem; font-weight: bold; text-align: center"
          v-if="selectedDeuda === 'emergencia'"
        ></Column>
        <Column
          field="costo"
          header="COSTO"
          style="font-size: 1rem; font-weight: bold; text-align: right"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.costo) }}
          </template>
        </Column>
        <Column
          field="descuento"
          header="DESC."
          style="font-size: 1rem; font-weight: bold; text-align: right"
          v-if="selectedDeuda === 'odontologia'"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.descuento ?? 0) }}
          </template>
        </Column>
        <Column
          field="acuenta"
          header="A CUENTA"
          style="
            font-size: 1rem;
            font-weight: bold;
            text-align: right;
            width: 72px;
          "
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.acuenta) }}
          </template>
        </Column>
        <Column
          field="saldo"
          header="SALDO"
          style="font-size: 1rem; font-weight: bold; text-align: right"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.saldo) }}
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-content-end mt-4">
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-danger mr-2 p-button-lg"
        @click="cerrarModalDeudas"
      />
      <Button
        :disabled="!tieneDeudas"
        label="PAGAR DEUDAS"
        icon="pi pi-save"
        class="p-button-info p-button-lg ml-2"
        @click="pagarDeudasCliente()"
      />
    </div>
  </Dialog>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="cargarClientesListado"
  >
  </ClienteCreate>
  <ClienteFactura
    :show="clienteFacturaModal"
    :cliente_factura="cliente_factura"
    @closeModal="cerrarModalClienteFactura"
    @actualizarListado="buscarClientePorNit"
  >
  </ClienteFactura>
  <MedicoCreate
    :show="medicoModal"
    :medico="medico"
    @closeModal="cerraModalMedico"
    @actualizarListado="cargarMedicosListado"
  >
  </MedicoCreate>

  <ProducoListadoModal
    @closeModal="cerrarModalProductoResultados"
    :show="productosResultados"
    :productos="productosFiltrados"
    @agregarProductos="agregarProductos"
  ></ProducoListadoModal>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import ProductService from "@/service/ProductService";
import VentaService from "@/service/VentaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteFactura from "@/module/clientes/ClienteFactura.vue";
import MedicoCreate from "@/module/medicos/MedicoCreate.vue";
import CajaService from "@/service/CajaService";
import { useAuth } from "@/stores";
import TipoPagoService from "@/service/TipoPagoService";
import MetodoPagoService from "@/service/MetodoPagoService";
import PaqueteProductoService from "@/service/PaqueteProductoService";
import FacturaService from "@/service/FacturaService";
import MedicoService from "@/service/MedicoService";
import ParametrosSistemaService from "@/service/ParametrosSistemaService";
import ProducoListadoModal from "@/module/productos/ProductoListadoModal.vue";
import EmpresaService from "@/service/EmpresaService";

export default {
  components: {
    ClienteCreate,
    ClienteFactura,
    ProducoListadoModal,
    MedicoCreate,
  },
  data() {
    return {
      masFiltrosBusqueda: false,
      buscarProductoPorSelected: ["nombre_comercial"],
      buscarProductoPorListado: [
        { label: "Nombre Comercial", value: "nombre_comercial" },
        { label: "Médico", value: "principio_activo" },
      ],
      permitir_facturar: false,
      facturar: false,
      cotizar: false,
      guardando: false,
      clienteSelect: null,
      clientes: [],
      razon_social: null,
      nit: 0,
      productoSelecionado: null,
      productosSelecionado: null,
      productosListadoFiltrado: [],
      productosVentas: [],
      stockSucursales: [],
      subtotal: 0,
      descuento: 0,
      total_venta: 0,
      efectivo: 0,
      monto_pago: 0, //monto de pago en efectivo
      clienteModal: false,
      clienteFacturaModal: false,
      cliente_factura: { estado: { label: "Activo", value: 1 } },
      cliente: { estado: { label: "Activo", value: 1 } },
      medicoModal: false,
      medico: { estado: { label: "Activo", value: 1 } },
      /* fecha_venta: null, */
      permitirVenta: false,
      cargandoComponente: false,
      sucursal_id: null,
      caja: null,
      codProdSelected: null,
      codProdListadoFiltrado: [],
      desc_venta_porcentaje: null,
      aplica_descuento: false,
      tipo_pagos: [],
      tipo_pagoSelect: 1,
      metodo_pagos: [],
      metodo_pagoSelect: 1,
      dias_credito: 0,
      a_cuenta: 0,
      saldo: 0,
      observacion: "",
      observacion_check: false,
      a_credito_check: false,
      metodo_pago_check: false,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      vender_paquete: false,
      paqueteSelected: null,
      paqueteproductos: [],
      paqueteproductosListado: [],
      entregado: 0,
      medico_referencia: true,
      medicos: [],
      medicoSelected: null,
      productosResultados: false,
      productosFiltrados: [],
      actividades_economicas: [],
      actividad_economica_selected: null,
      nro_tarjeta: null,
      openHistorialCliente: false,
      historiales_cliente: [],
      solicitante: false,
      solicitanteSelected: null,
      deudas_clientes: [],
      modalDeudasCliente: false,
      es_de_odontologia: false,
      cargandoDeuda: false,
      cliente_saldo_final: 0,
      es_abonar_cuenta: false,
      parametros_sistema: {},
      abonar_cuenta: false, // esto es permiso para abonar a cuenta
      es_de_emergencia: false,
      deudas_emergencias: [],
      selectedDeuda: "odontologia", // Default view
      ver_medico_detalle: false,
      permitir_pago_qr_banco: false, //permiso para generar qr de banco
      generar_qr: false,
      qrImage: "",
      qrId: null,
      generando_qr: false,
      estado_qr_generado: 0,
      cancelando_qr: false,
    };
  },
  clienteService: null,
  productService: null,
  ventaService: null,
  cajaService: null,
  auth: null,
  tipoPagoService: null,
  metodoPagoService: null,
  paqueteProductoService: null,
  medicoService: null,
  facturaService: null,
  parametrosSistemaService: null,
  empresaService: null,
  verificarQRInterval: null,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    this.productService = new ProductService();
    this.ventaService = new VentaService();
    this.cajaService = new CajaService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.paqueteProductoService = new PaqueteProductoService();
    this.medicoService = new MedicoService();
    this.facturaService = new FacturaService();
    this.parametrosSistemaService = new ParametrosSistemaService();
    this.empresaService = new EmpresaService();
  },
  mounted() {
    this.datos_venta_general();
  },
  computed: {
    // si mi venta es al credito entonces en efectivo se registra el monto  a cuenta el cambio es el total efectivo - a cuenta
    cambio() {
      if (this.tipo_pagoSelect == 2) {
        return this.efectivo - this.a_cuenta;
      } else {
        return (
          this.efectivo +
          this.monto_pago +
          parseFloat(this.cliente_saldo_final) -
          this.total_venta
        );
      }
    },
    computedBuscarProductoPorSelected() {
      return this.buscarProductoPorSelected.includes("principio_activo");
    },
    minimo_recibir() {
      let minimo =
        this.total_venta -
        this.a_cuenta -
        this.monto_pago -
        parseFloat(this.cliente_saldo_final);
      return minimo;
    },
    tieneDeudas() {
      return (
        this.deudas_clientes.length > 0 || this.deudas_emergencias.length > 0
      );
    },
  },
  methods: {
    cancelarQR() {
      let datos = {
        qrId: this.qrId,
      };
      this.cancelando_qr = true;
      this.ventaService.cancelarQR(datos).then((response) => {
        if (response.status == 200) {
          this.qrImage = null;
          this.qrId = null;
          this.generar_qr = false;
          this.cancelando_qr = false;
          this.$toast.add({
            severity: "success",
            summary: "QR Cancelado",
            detail: response.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
          this.cancelando_qr = false;
        }
      });
    },
    verificarEstadoQR() {
      if (this.qrImage) {
        let datos = {
          qrId: this.qrId,
        };
        this.ventaService.verificarEstadoQR(datos).then((response) => {
          this.estado_qr_generado = response.estado_qr_generado;
          if (response.estado_qr_generado == 1) {
            this.detenerVerificacion();
            this.$toast.add({
              severity: "success",
              summary: "QR Pagado",
              detail: "El QR ha sido pagado correctamente",
              life: 3000,
            });
          }
        });
      }
    },
    generarQR() {
      let datos = {
        monto: this.monto_pago,
      };
      this.generando_qr = true;
      this.ventaService.generarQR(datos).then((response) => {
        if (response.status == 200) {
          this.qrImage = response.qrImage;
          this.qrId = response.qrId;
          this.generar_qr = true;
          this.generando_qr = false;
          this.iniciarVerificacion();
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
          this.generando_qr = false;
        }
      });
    },
    iniciarVerificacion() {
      this.verificarQRInterval = setInterval(() => {
        this.verificarEstadoQR(); // Verificamos el estado cada 5 segundos
      }, 5000);
    },
    detenerVerificacion() {
      if (this.verificarQRInterval) {
        clearInterval(this.verificarQRInterval);
        this.verificarQRInterval = null;
      }
    },
    cerrarModalDeudas() {
      this.modalDeudasCliente = false;
      this.selectedDeuda = "odontologia"; // Reset to default when closing
    },
    pagarDeudasCliente() {
      let productosSeleccionados =
        this.selectedDeuda === "odontologia"
          ? this.deudas_clientes
          : this.deudas_emergencias;
      try {
        productosSeleccionados.forEach((producto) => {
          let ultimo_precio = parseFloat(
            producto.costo ?? producto.precio_sugerido
          );
          this.agregarDetalleVenta({
            ...producto,
            plan_tratamiento_id: producto.plan_tratamiento_id,
            emergencia_id: producto.emergencia_id,
            cantidad: parseInt(producto.cantidad ?? 1),
            producto_id: producto.id,
            sucursal_id: this.sucursal_id,
            nombre_producto: producto.descripcion,
            cantidad_venta: parseInt(producto.cantidad ?? 1),
            descuento_neto: 0,
            descuento_porcentaje: 0,
            ultimo_precio: ultimo_precio,
            precio_sugerido: ultimo_precio,
            total: ultimo_precio,
          });
          if (producto.medico_id) {
            this.medicoSelected = producto.medico_id ?? null;
          }
          if (producto.descuento > 0) {
            this.aplica_descuento = true;
          }
        });
        this.modalDeudasCliente = false;
      } catch (error) {
        console.log(error);
      }
    },
    verDeudaCliente() {
      let datos = {
        cliente_id: this.clienteSelect.id,
        es_de_odontologia: this.es_de_odontologia,
        es_de_emergencia: this.es_de_emergencia,
      };
      this.cargandoDeuda = true;
      this.ventaService.getDeudaCliente(datos).then((response) => {
        if (response.success == false) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
          this.cargandoDeuda = false;
        } else {
          this.deudas_clientes = response.deudas_clientes; // Odontologia
          this.deudas_emergencias = response.deudas_emergencias; // Emergencia
          //pregunto si hay deuda de odontologia o emergencia para mostrar el modal
          if (
            this.deudas_clientes.length > 0 ||
            this.deudas_emergencias.length > 0
          ) {
            this.modalDeudasCliente = true;
            this.$toast.add({
              severity: "success",
              summary: "Deuda del Cliente",
              detail: response.message,
              life: 3000,
            });
            this.cargandoDeuda = false;
          } else {
            this.$toast.add({
              severity: "info",
              summary: "Deuda del Cliente",
              detail: response.message,
              life: 3000,
            });
            this.cargandoDeuda = false;
          }
        }
      });
    },
    verificarCasoUso() {
      if (this.buscarProductoPorSelected.includes("principio_activo")) {
        return true;
      } else {
        return false;
      }
    },
    actualizarPanelMasFiltros(e) {
      this.masFiltrosBusqueda = e;
      if (!this.masFiltrosBusqueda) {
        this.limpiarFiltrosBusquedaProducto();
      }
    },
    limpiarFiltrosBusquedaProducto() {
      this.buscarProductoPorSelected = ["nombre_comercial"];
    },
    nuevoAperturaModalHistorialCliente() {
      this.verHistorialCliente();
    },
    verHistorialCliente() {
      let datos = {
        cliente_id: this.clienteSelect.id,
      };
      this.ventaService.getHistorialCliente(datos).then((response) => {
        this.historiales_cliente = response.historiales_cliente ?? [];
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Historial de Atenciones",
            detail: response.mensaje,
            life: 3000,
          });
          this.openHistorialCliente = true;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
        }
      });
    },
    cambiarActividadEconomica() {
      let datos = {
        actividad_economica_id: this.actividad_economica_selected,
      };
      this.empresaService.changeActividad(datos).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "ACTIVIDAD ECONOMICA",
            detail: data.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error al cambiar la actividad economica",
            life: 3000,
          });
        }
      });
    },
    calcular_agregar_des_total_venta() {
      this.calcular_descuento_venta_porcentaje();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    calcular_agregar_des_total_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    cerrarModalProductoResultados() {
      this.productosResultados = false;
    },
    activarModalProductosFiltrados() {
      this.productosResultados = true;
      this.productosFiltrados = this.productosListadoFiltrado;
    },
    datos_venta_general() {
      let datos = {
        verificar_caja: true,
        facturar: "facturar",
      };
      this.ventaService.VentaGeneral(datos).then((response) => {
        if (response.success) {
          if (response.datos.caja.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se encontró una caja abierta",
            });
            this.permitirVenta = false;
            this.cargandoComponente = true;

            return;
          }

          this.caja = response.datos.caja[0];
          this.sucursal_id = response.datos.caja[0].sucursal_id;
          this.permitirVenta = true;

          this.clientes = response.datos.clientes;
          //this.clienteSelect = this.clientes.length ? this.clientes[0] : null;
          this.tipo_pagos = response.datos.tipo_pagos;
          this.metodo_pagos = response.datos.metodo_pagos;
          this.paqueteproductos = response.datos.paquetes;
          this.medicos = response.datos.medicos;

          if (response.datos.facturar) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
          this.actividades_economicas =
            response.datos.actividades_economicas || [];
          if (this.actividades_economicas.length) {
            this.actividad_economica_selected =
              response.datos.actividad_economica_select.codigo;
          }
          this.parametros_sistema = response.datos.parametros_sistema ?? {};
          this.parametros_sistema.some((parametro) => {
            if (parametro.nombre == "Odontologia") {
              this.es_de_odontologia = true;
            }
            if (parametro.nombre == "AbonarAcuenta") {
              this.abonar_cuenta = true;
            }
            if (parametro.nombre == "Emergencia") {
              this.es_de_emergencia = true;
            }
            if (parametro.nombre == "permitir_pago_qr_banco") {
              this.permitir_pago_qr_banco = true;
            }
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 60000,
          });
        }

        this.cargandoComponente = true;
      });
    },
    verificarFacturar() {
      this.parametrosSistemaService
        .verificarPermiso("facturar")
        .then((response) => {
          if (response.permiso) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
        });
    },
    obtenerMedicos() {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
      });
    },
    obtenerPaquetes() {
      this.paqueteProductoService.getPaqueteProductoAll().then((response) => {
        this.paqueteproductos = response.paqueteproductos;
      });
    },
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response.tipo_pagos;
      });
    },
    obtenerMetodosPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    buscarClientePorNit() {
      //BUSCO EL CLIENTE POR NIT Y SI NO EXISTE LO CREO
      if (this.nit != null) {
        this.clienteService
          .buscarClientePorNit({ nit: this.nit })
          .then((response) => {
            if (response.cliente != null) {
              this.$toast.add({
                severity: "success",
                summary: "Nit encontrado",
                detail: "Se cargaron los datos del cliente",
                life: 3000,
              });
              /* this.razon_social = response.cliente.nombre_cliente; */
              let nombre_cliente =
                response.cliente.nombre_cliente ?? response.cliente;
              this.razon_social = nombre_cliente ?? "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Cliente no encontrado",
                detail: "Registre los datos del cliente",
                life: 3000,
              });
              /* this.cliente.razon_social = this.razon_social;
              this.cliente.nit = this.nit;
              this.cliente_id = this.clienteSelect.id; */
              this.razon_social = "";
              this.activarClienteFactura();
            }
          });
      }
    },
    aplicar_descuento_productos_detalle() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_porcentaje = this.desc_venta_porcentaje;
        producto.descuento_neto =
          producto.ultimo_precio *
          producto.cantidad_venta *
          (producto.descuento_porcentaje / 100);
      });
      this.calcular_subtotal();
    },
    buscarCliente() {
      let datos = {
        texto: this.clienteSelect ?? null,
      };
      this.clienteService.buscarXNombre(datos).then((response) => {
        if (response.status == 400 || response.clientes.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el cliente",
            life: 3000,
          });
          return;
        }
        this.clientes = [...response.clientes];
      });
    },
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirVenta = true;
        } else {
          this.permitirVenta = false;
        }

        this.cargandoComponente = true;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numero);
    },
    verificarMedico() {
      if (this.medico_referencia == true && this.medicoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "MÉDICO",
          detail: "DEBE SELECCIONAR UN MÉDICO",
          life: 10000,
        });
        return true;
      }
      return false;
    },
    verificarSoliciante() {
      if (this.solicitante == true && this.solicitanteSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "SOLICITANTE",
          detail: "DEBE SELECCIONAR UN SOLICITANTE",
          life: 10000,
        });
        return true;
      }
      return false;
    },
    verificarCliente() {
      if (!this.clienteSelect) {
        this.$toast.add({
          severity: "error",
          summary: "CLIENTE",
          detail: "DEBE SELECCIONAR UN CLIENTE",
          life: 10000,
        });
        return true;
      }
      return false;
    },
    verificarMetodoPago() {
      if (
        this.metodo_pagoSelect === 2 &&
        this.facturar === true &&
        (!this.nro_tarjeta || this.nro_tarjeta.toString().length < 8)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Número de tarjeta",
          detail: "El número de tarjeta debe tener al menos 8 dígitos",
          life: 60000,
        });
        return true;
      }
      return false;
    },
    verificarDatosFactura() {
      if (this.facturar === true && !this.nit) {
        this.$toast.add({
          severity: "error",
          summary: "NIT",
          detail: "DEBE INGRESAR EL NIT",
          life: 10000,
        });
        return true;
      }
      return false;
    },
    guardarVenta() {
      if (
        this.verificarMedico() ||
        this.verificarSoliciante() ||
        this.verificarCliente() ||
        this.verificarMetodoPago() ||
        this.verificarDatosFactura()
      ) {
        return;
      }
      let datos = {
        razon_social: this.razon_social,
        nit: this.nit,
        cliente_id: this.clienteSelect.id,
        total_cancelar: this.total_venta,
        descuento: this.descuento ? this.descuento : 0,
        efectivo: this.efectivo ? this.efectivo : 0,
        productos: this.productosVentas,
        caja_id: this.caja.id,
        tipo_pago_id: this.tipo_pagoSelect,
        metodo_pago_id: this.metodo_pagoSelect,
        monto_pago: this.monto_pago ? this.monto_pago : 0,
        dias_credito: this.dias_credito,
        a_cuenta: this.a_cuenta ? this.a_cuenta : 0,
        cambio: this.cambio,
        facturado: this.facturar ? 1 : 0,
        observacion: this.observacion,
        nombre_paquete: this.paqueteSelected ? this.paqueteSelected : null,
        nro_tarjeta: this.nro_tarjeta ? this.nro_tarjeta : null,
        nombre_solicitante: this.solicitanteSelected
          ? this.solicitanteSelected
          : null,
        es_de_odontologia: this.es_de_odontologia,
        es_abonar_cuenta: this.es_abonar_cuenta,
        es_de_emergencia: this.es_de_emergencia,
      };

      if (this.medico_referencia == true) {
        datos = { ...datos, medico_id: this.medicoSelected };
      }

      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿ESTÁS SEGURO DE GUARDAR LA VENTA?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Guardar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        closable: false,
        closeOnEscape: false,
        accept: () => {
          this.guardando = true;
          this.ventaService.crear(datos).then((res) => {
            try {
              if (res.status == 400) {
                this.guardando = false;
                this.$toast.add({
                  severity: "error",
                  summary: "VENTA",
                  detail: res.mensaje,
                  life: 60000,
                });
                return;
              } else {
                this.$toast.add({
                  severity: "success",
                  summary: "VENTA",
                  detail: res.mensaje,
                  life: 3000,
                });
                this.$router.push({
                  name: "venta_detalle",
                  params: { id: res.venta.id },
                });
                this.guardando = false;
              }
            } catch (error) {
              this.guardando = false;
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "Error al guardar la venta",
                life: 60000,
              });
            }
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "Venta Cancelada",
            life: 3000,
          });
          this.guardando = false;
        },
      });
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        nombre: this.clienteSelect.nombre ?? this.clienteSelect,
      };
    },
    activarClienteFactura() {
      this.clienteFacturaModal = true;
      this.cliente_factura = {
        estado: { label: "Activo", value: 1 },
        ci_nit: this.nit ? this.nit : null,
      };
    },
    cerrarModalClienteFactura() {
      this.clienteFacturaModal = false;
      this.cliente_factura = {};
    },
    activarMedico() {
      this.medicoModal = true;
      this.medico = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerraModalMedico() {
      this.medicoModal = false;
      this.medico = {};
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      /*  if (field == "cantidad_venta") {
        if (data.cantidad_venta > data.cantidad) {
          data.cantidad_venta = parseInt(data.cantidad);
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "No hay suficiente stock",
            life: 3000,
          });
        }
      } */

      if (field == "cantidad_venta") {
        /* if (data.cantidad_venta > data.cantidad) {
          data.cantidad_venta = null;
          this.$toast.add({
            severity: "error",
            summary: "REVISAR STOCK DE PRODUCTO",
            detail: "No hay suficiente stock: " + data.nombre_producto,
            life: 60000,
          });
        } else { */
        data["total"] = data["cantidad_venta"] * data["ultimo_precio"];
        this.calcular_subtotal();
        /* } */
      }
      if (field == "ultimo_precio" && data["cantidad_venta"] > 0) {
        data["ultimo_precio"] = data["ultimo_precio"] ?? 0;
        data["total"] = data["cantidad_venta"] * data["ultimo_precio"];
        this.calcular_subtotal();
        if (this.aplica_descuento == "aplica_descuento") {
          this.calcular_agregar_des_total_venta_porcentaje();
        }
      }
      if (field == "descuento_neto" && data["cantidad_venta"] > 0) {
        if (newValue > data["total"]) {
          data["descuento_neto"] = data["total"];
          data["descuento_porcentaje"] =
            (data["descuento_neto"] * 100) / data["total"];
          this.calcular_descuentos_totales_venta();
          this.$toast.add({
            severity: "error",
            summary: "REVISAR DESCUENTO",
            detail: "El descuento no puede ser mayor al total",
            life: 60000,
          });
          return;
        }
        data["descuento_porcentaje"] = (newValue * 100) / data["total"];
        this.calcular_descuentos_totales_venta();
      }
      if (field == "descuento_porcentaje" && data["cantidad_venta"] > 0) {
        data["descuento_neto"] = (newValue * data["total"]) / 100;
        this.calcular_descuentos_totales_venta();
      }
    },
    calcular_descuentos_totales_venta() {
      let desc_venta_neto = 0;
      /* let desc_venta_porcentaje = 0; */
      this.productosVentas.forEach((producto) => {
        desc_venta_neto += producto.descuento_neto;
        /* desc_venta_porcentaje += producto.descuento_porcentaje; */
      });
      this.descuento = desc_venta_neto;
      /* this.desc_venta_porcentaje = desc_venta_porcentaje; */
      this.desc_venta_porcentaje = (this.descuento * 100) / this.subtotal;
      this.calcular_total_venta();
    },
    calcular_subtotal() {
      this.subtotal = 0;
      this.productosVentas.forEach((product) => {
        this.subtotal += product.total;
      });
      this.total_venta = this.subtotal - this.descuento;
      if (this.descuento >= 0) {
        this.agregar_descuento_detalle_productosventas();
      }
    },
    agregar_descuento_detalle_productosventas() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_neto =
          (this.descuento * producto.total) / this.subtotal;
        producto.descuento_porcentaje =
          (producto.descuento_neto * 100) / producto.total;
      });
    },
    calcular_total_venta() {
      this.total_venta = this.subtotal - this.descuento;
    },
    calcular_descuento_venta_porcentaje() {
      this.desc_venta_porcentaje =
        (this.descuento * 100) / this.subtotal /* .toFixed(2) */;
    },
    calcular_descuento_venta_neto() {
      this.descuento = Math.round(
        (this.desc_venta_porcentaje * this.subtotal) / 100
      );
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: "¿ESTÁS SEGURO DE ELIMINAR EL PRODUCTO?",
        acceptLabel: "Si, Eliminar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.productosVentas.splice(datos.index, 1);
          this.calcular_subtotal();
        },
      });
    },
    buscarCodProducto() {
      const datos = {
        texto: this.codProdSelected,
      };
      this.productService
        .buscarXCodProducto(datos)
        .then((response) => {
          let product = response.productos[0];
          console.log(product);

          this.codProdSelected = null;
          this.codProdListadoFiltrado = [];
        })
        .catch((error) => console.error(error));
    },
    buscarProducto(event) {
      setTimeout(() => {
        let _text = event.query ?? event.value;
        this.productService
          .buscarXNombreVenta({
            texto: _text,
            buscarProductoPorSelected: this.buscarProductoPorSelected,
            /* busqueda_caso_uso: this.busqueda_caso_uso, */
            sucursal_id: this.sucursal_id,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 200);
    },
    cargarClientesListado(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          if (selected) {
            this.clienteSelect = selected;
            this.clientes = [];
          } else {
            this.clientes = response;
          }
        })

        .catch((error) => console.log(error));
    },
    cargarMedicosListado(medico = null) {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
        if (medico) {
          this.medicos.forEach((element) => {
            if (element.id == medico.id) {
              this.medicoSelected = element.id;
            }
          });
        }
      });
    },
    agregarDetalleVenta(data) {
      if (this.productosVentas.length > 0) {
        let existe = this.productosVentas.find(
          (producto) =>
            producto.id == data.id &&
            this.es_de_odontologia == false &&
            this.es_de_emergencia == false
        );
        if (existe) {
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "El producto ya se encuentra en la venta",
            life: 60000,
          });
          return;
        }
      }

      let precio = data.ultimo_precio ?? data.precio_sugerido;
      this.productosVentas.push({
        cantidad_venta: 1,
        descuento_neto: 0,
        descuento_porcentaje: 0,
        ...data,
        ultimo_precio: precio,
        total: precio * data.cantidad_venta,
      });

      this.stockSucursales = [];
      this.productosListadoFiltrado = [];
      this.productoSelecionado = null;
      this.calcular_subtotal();
    },
    agregarProductos(productosSeleccionados) {
      productosSeleccionados.forEach((producto) => {
        try {
          if (
            producto.stock == null ||
            producto.stock == "" ||
            producto.stock == 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "VENTA/STOCK",
              detail:
                "El producto: " + producto.descripcion + " no tiene stock",
              life: 60000,
            });
            return;
          }
          this.agregarDetalleVenta({
            ...producto,
            cantidad: producto.stock[0].cantidad,
            producto_id: producto.id,
            sucursal_id: producto.stock[0].sucursal_id,
            nombre_producto: producto.descripcion,
            cantidad_venta: 1,
            descuento_neto: 0,
            descuento_porcentaje: 0,
            ultimo_precio: parseFloat(producto.stock[0].precio_venta ?? 0),
            precio_sugerido: parseFloat(producto.stock[0].precio_venta ?? 0),
            total: parseFloat(producto.stock[0].precio_venta * 1 ?? 0),
          });
        } catch (error) {
          console.log(error);
        }
      });

      this.cerrarModalProductoResultados();
    },
    agregarProductosPaqueteAlDetalle() {
      let datos = {
        paquete_producto_id: this.paqueteSelected,
      };
      this.paqueteProductoService
        .getPaqueteProductoById(datos)
        .then((response) => {
          /* this.paqueteSelected = null; */
          response.paqueteproductosListado.forEach((producto) => {
            let paquete_cantidad = producto.cantidad;
            let precio =
              producto.stock_para_caja_activa == null
                ? producto.producto.precio_sugerido
                : producto.stock_para_caja_activa.ultimo_precio;
            //comparo la cantidad del stock con la cantidad del paquete
            if (producto.stock_para_caja_activa.cantidad < paquete_cantidad) {
              this.$toast.add({
                severity: "error",
                summary: "Revisar stock del producto",
                detail:
                  "El producto no tiene stock suficiente: " +
                  producto.producto.descripcion,
                life: 60000,
              });
              return;
            }
            this.agregarDetalleVenta({
              ...producto,
              id: producto.producto_id,
              cantidad: producto.stock_actual,
              sucursal_id: producto.sucursal_id,
              cantidad_venta: paquete_cantidad,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              ultimo_precio: parseFloat(precio),
              precio_sugerido: parseFloat(producto.producto.precio_sugerido),
              total: parseFloat(precio * producto.cantidad),
            });
            /* this.productosVentas.push(); */

            this.calcular_subtotal();
          });
        })
        .catch((error) => console.log(error));
    },
    fechaActual() {
      return new Date().toLocaleDateString();
    },
  },
  watch: {
    clienteSelect(datos) {
      if (this.facturar == true) {
        // si es factura
      } else {
        this.nit = datos.ci_nit;
        this.razon_social = datos.nombre;
        this.cliente_saldo_final = datos.saldo_final ?? 0;
      }
    },

    productoSelecionado(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido ?? 0),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
          });
        }
      }
    },
    codProdSelected(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
            this.codProdSelected = null;
          });
        }
      }
    },
    total_venta() {
      if (this.tipo_pagoSelect == 1) {
        this.efectivo = this.total_venta;
      } else {
        this.a_cuenta = 0;
        this.efectivo = 0;
      }
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
      } else {
        this.monto_pago = this.total_venta;
      }
    },
    a_cuenta() {
      if (this.a_cuenta > this.total_venta) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El monto a cuenta no puede ser mayor al total de la venta",
          life: 60000,
        });
        this.a_cuenta = 0;
        return;
      }
      this.efectivo = this.a_cuenta;
    },
    monto_pago() {
      if (this.tipo_pagoSelect == 2) {
        // si es a credito
        this.efectivo = this.a_cuenta;
      } else {
        this.efectivo = this.total_venta - this.monto_pago;
      }
    },
    metodo_pagoSelect() {
      let efectivo = this.a_credito_check
        ? 0
        : this.total_venta - this.a_cuenta;
      let monto_pago = this.a_credito_check
        ? 0
        : this.total_venta - this.efectivo;

      if (this.metodo_pagoSelect == 1) {
        this.efectivo = efectivo;
        this.monto_pago = 0;
        this.metodo_pago_check = false;
        this.nro_tarjeta = "";
      } else {
        this.efectivo = 0;
        this.monto_pago = monto_pago;
      }
    },
    metodo_pago_check() {
      if (this.metodo_pago_check) {
        this.metodo_pagoSelect = 6;
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      } else {
        this.metodo_pagoSelect = 1;
        this.metodo_pago_check = false;
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
        this.nro_tarjeta = "";
      }
    },
    a_credito_check() {
      if (this.a_credito_check) {
        this.tipo_pagoSelect = 2;
        this.dias_credito = 30;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = 0;
      } else {
        this.tipo_pagoSelect = 1;
        this.a_credito_check = false;
        this.a_cuenta = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
        this.monto_pago = 0;
      }
    },
    aplica_descuento() {
      if (this.aplica_descuento == true) {
        this.desc_venta_porcentaje = 0;
        this.calcular_agregar_des_total_venta_porcentaje();
        /* this.calcular_descuento_venta_porcentaje();
        this.agregar_descuento_detalle_productosventas();
        Math.round(this.descuento * 100) / 100; */
      } else {
        this.desc_venta_porcentaje = 0;
      }
    },
    facturar() {
      this.nit = "";
      this.razon_social = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #747474;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.p-fondo {
  background-color: #6abad0;
  border-radius: 0.5em;
  border: 1px solid #002236;
}
.precio {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.p-inputgroup-addon {
  background-color: #ffffff;
  font-weight: bold;
  color: #000000;
  border: none;
  margin-right: none;
}
/* Estilo general para los botones */
.estiloBoton {
  background-color: #002236; /* Color oscuro para el botón seleccionado */
  color: #ffffff;
  border: none;
  border-radius: 0.5em;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s; /* Transición suave para el cambio de color */
}

/* Estilo para el botón no seleccionado */
.estiloBoton:not(.activo) {
  background-color: #d0d0d0; /* Color gris suave para el botón no seleccionado */
  color: #333333;
}

/* Estilo para el botón seleccionado */
.estiloBoton.activo {
  background-color: #004080; /* Color más claro o diferente para el botón seleccionado */
  color: #ffffff;
}

/* Hover para botones seleccionados y no seleccionados */
.estiloBoton:hover {
  background-color: #003366; /* Color ligeramente diferente para el hover en el botón seleccionado */
  color: #ffffff;
}

.estiloBoton:not(.activo):hover {
  background-color: #b0b0b0; /* Color ligeramente más oscuro para el hover en el botón no seleccionado */
  color: #333333;
}
</style>
